<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="border-0 p-3 d-flex justify-content-center" style="min-height: 60px">
				<olMap :webGLmarkers="webGLmarkers" popup="map-info" @mapClick="showInfo">
					<!-- START MAP INFO OVERLAY BOX -->
					<template #popup>
						<div id="map-info">
							<div class="popover-arrow" />
							<div v-if="device">
								<div class="close">
									<i class="flaticon-circle"></i>
								</div>
								<div
									id="map-info-title"
									:style="{
										background: `linear-gradient(200deg, ${device.colors[0]}, ${device.colors[1]})`,
									}"
								>
									<h5>
										<router-link v-if="device.SiteId" :to="{ name: 'site', params: { id: device.SiteId } }">
											{{ device.sitename.length > 20 ? device.sitename.substr(0, 19) + '...' : device.sitename }}
										</router-link>
										<div v-else>
											{{ device.sitename.length > 20 ? device.sitename.substr(0, 19) + '...' : device.sitename }}
										</div>
									</h5>
								</div>
								<div id="map-info-body">
									<div class="info-section" v-if="device.Name">
										<span class="info-section-title">Device</span>
										<h6>{{ device.Name }}</h6>
									</div>
									<div class="info-section">
										<span class="info-section-title">Position</span>
										<h6>{{ device.gps.lon }}, {{ device.gps.lat }}</h6>
									</div>
									<div class="info-section">
										<span class="info-section-title">Status</span>
										<h6>
											<span
												class="px-1"
												:style="{
													background: colors[device.status] || colors.default,
													'border-radius': '5px',
												}"
												>{{ device.status || 'UNKNOWN' }}</span
											>
											<span v-if="device.status_change"> as of {{ device.status_change }}</span>
										</h6>
									</div>
								</div>
							</div>
						</div>
					</template>
					<!-- END MAP INFO OVERLAY BOX -->
				</olMap>
			</div>
		</div>
	</div>
</template>

<script>
import { fromLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';

const setWebGlMarkers = function(data) {
	let colors = {
		ALARM: { r: 248, g: 119, b: 108 }, //red
		OK: { r: 101, g: 186, b: 75 }, //green
		SCPC: { r: 31, g: 44, b: 210 }, //blue
		DEACTIVATED: { r: 255, g: 255, b: 255 },
		default: { r: 188, g: 188, b: 188 }, //grey
	};
	let features = data
		.filter(device => {
			return device.gps;
		})
		.map(device => {
			let lon = device.gps.lon > 180 ? device.gps.lon - 360 : device.gps.lon;
			let color = device.status && colors[device.status] ? colors[device.status] : colors.default;
			return {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: fromLonLat([lon, device.gps.lat]),
				},
				properties: {
					name: device.sitename,
					DID: device.DID,
					deviceId: device.DeviceId,
					r: color.r,
					g: color.g,
					b: color.b,
				},
			};
		});
	return {
		geojson: {
			type: 'FeatureCollection',
			features: features,
		},
		style: {
			symbol: {
				symbolType: 'image',
				src: '/media/markers/marker_white.png',
				size: [18, 30],
				color: ['color', ['get', 'r'], ['get', 'g'], ['get', 'b'], 1],
				rotateWithView: false,
				offset: [0, 15],
			},
		},
	};
};

const setCanvasMarkers = data => {
	let features = data.map(device => {
		return {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: fromLonLat([device.gps.lon, device.gps.lat]),
			},
			properties: {
				name: device.sitename,
				DID: device.DID,
				status: device.status,
			},
		};
	});
	return [
		{
			geojson: {
				type: 'FeatureCollection',
				features: features,
			},
			style: feature => {
				let srcs = {
					OK: '/media/markers/marker_green.png',
					ALARM: '/media/markers/marker.png',
					SCPC: 'media/markers/marker_blue.png',
					DEACTIVATED: 'media/markers/marker_white.png',
					default: '/media/markers/marker_grey.png',
				};
				let status = feature.getProperties().status;
				return new Style({
					image: new Icon({
						anchor: [0.5, 1],
						anchorXUnits: 'fraction',
						anchorYUnits: 'fraction',
						src: srcs?.[status] || srcs['default'],
					}),
				});
			},
		},
	];
};

export default {
	name: 'DashMap',
	components: {
		olMap: () => import('@/view/content/lib/ol.vue'),
	},
	data() {
		return {
			webGLmarkers: null,
			canvasMarkers: null,
			devices: null,
			device: null,
			colors: {
				OK: ['#c4fce5', '#37c583'],
				ALARM: ['#f7ccd0', '#ED6B75'],
				DEACTIVATED: ['#e2eeff', '#bac3d1'],
				SCPC: ['#bdd9fc', '#659BE0'],
				default: ['#e2eeff', '#bac3d1'],
			},
		};
	},
	methods: {
		showInfo(marker) {
			let markerId = marker.get('deviceId');
			this.device = this.devices.find(device => device.DeviceId === markerId);
			this.device.colors = this.colors[this.device.status] || this.colors.default;
		},
	},
	created() {
		this.$http.get('dashboard/googlemap').then(resp => {
			this.devices = Object.freeze(resp.data.data.devices);
			this.webGLmarkers = setWebGlMarkers(this.devices);
			// this.canvasMarkers = setCanvasMarkers(this.devices);
		});
	},
};
</script>

<style scoped>
#map-info {
	width: 275px;
	background: rgba(255, 255, 255, 1);
	box-shadow: 2px 4px 13px -5px rgb(99, 123, 124);
	border-radius: 5px;
}
.popover-arrow {
	left: 0;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 14px;
	width: 14px;
}
#map-info-title {
	padding: 10px;
	border-radius: 5px 5px 0 0;
}
#map-info-title a {
	color: rgb(5, 29, 168);
}
#map-info-body {
	padding: 10px;
}
#map-info-body .info-section .info-section-title {
	font-size: 1em;
	color: rgb(92, 91, 91);
}
.close {
	position: absolute;
	right: 5px;
	top: 2px;
}
.close i {
	color: rgb(15, 15, 15);
	cursor: pointer;
}
</style>
